import React from 'react';
import { Box, xcss, Text, Stack } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_PLATFORM,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { FeedbackCollectorButton } from '@atlassian/jira-feedback-collector/src/ui/button/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation';
import messages from './messages';

const FEEDBACK_GROUP_ID = 'issue-navigator';

const FeedbackTitleDetails = () => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={feedbackTitleDetailsContainerStyles}>
			<Stack space="space.150">
				<Text as="p">{formatMessage(messages.feedbackTitleDetails)}</Text>
				<Text as="p">
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.feedbackTitleDetailsContactSupportIssueTermRefresh
							: messages.feedbackTitleDetailsContactSupport,
					)}
				</Text>
			</Stack>
		</Box>
	);
};

const Feedback = () => {
	const { formatMessage } = useIntl();

	const feedbackContext = [
		{
			id: EntryKeys.LOCATION,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			value: normaliseUrl(window?.location?.href ?? ''),
		},
		{
			id: EntryKeys.SCREEN_RESOLUTION,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			value: `${window?.screen?.width ?? 'unknown'} x ${window?.screen?.height ?? 'unknown'}`,
		},
		{
			id: EntryKeys.USER_AGENT,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			value: window?.navigator?.userAgent ?? 'unknown',
		},
	];

	const feedbackGroupLabels = {
		// Custom dropdown placeholder
		empty: {
			selectOptionLabel: formatMessage(messages.feedbackTypePlaceholder),
		},
		// Use default labels for other options
		bug: {},
		comment: {},
		suggestion: {},
		question: {},
	};

	return (
		<FeedbackCollectorButton
			testId="issue-navigator.common.ui.feedback.feedback-button"
			entrypointId={ENTRYPOINT_ID_JIRA_PLATFORM}
			feedbackGroupId={FEEDBACK_GROUP_ID}
			feedbackTitle={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.feedbackTitleIssueTermRefresh
					: messages.feedbackTitle,
			)}
			feedbackTitleDetails={<FeedbackTitleDetails />}
			feedbackContext={feedbackContext}
			feedbackGroupLabels={feedbackGroupLabels}
		/>
	);
};

export default Feedback;

const feedbackTitleDetailsContainerStyles = xcss({
	marginBottom: 'space.300',
});
