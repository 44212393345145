import { createActionsHook, createStateHook } from '@atlassian/react-sweet-state';
import { Store } from './index.tsx';
import type { State, Actions, InfiniteScrollProps } from './types.tsx';

export const useSearchKey = createStateHook(Store, { selector: (state) => state.searchKey });

export const useIssueSearchActions = createActionsHook(Store);

/** Get all infinite scroll related props to avoid prop drilling. */
export const useInfiniteScrollProps = createStateHook<State, Actions, InfiniteScrollProps>(Store, {
	selector: ({
		onLoadNext,
		onLoadPrevious,
		isLoadingNext,
		isLoadingPrevious,
		hasNext,
		hasPrevious,
	}) => ({
		onLoadNext,
		onLoadPrevious,
		isLoadingNext,
		isLoadingPrevious,
		hasNext,
		hasPrevious,
	}),
});
