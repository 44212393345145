import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import {
	categoryIdForStatusCategory,
	DONE,
	statusCategoryForId,
} from '@atlassian/jira-common-constants/src/status-categories';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { shouldDisplayStrikethrough } from '@atlassian/jira-issue-key-with-resolution/src/common/utils.tsx';
import type { footer_issueNavigator_FooterInternal$key } from '@atlassian/jira-relay/src/__generated__/footer_issueNavigator_FooterInternal.graphql';
import type { main_issueNavigator_Card_fieldSetsForIssueSearchView$data } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Card_fieldSetsForIssueSearchView.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { isNonNullish } from '../../../../../../common/utils';
import messages from './messages';

type FieldSetEdges = main_issueNavigator_Card_fieldSetsForIssueSearchView$data['edges'];
type FieldSetEdge = NonNullable<FieldSetEdges>[number];

type FieldSetNode = NonNullable<FieldSetEdge>['node'];
type FieldSetNodeFields = NonNullable<FieldSetNode>['fields'];

type FieldEdges = NonNullable<FieldSetNodeFields>['edges'];
type FieldEdge = NonNullable<FieldEdges>[number];

/**
 * Find a field edge by fieldId and run it through the provided mapper function.
 */
export const byFieldId = <T,>(
	edges: FieldSetEdges,
	fieldId: string,
	mapperFn: (arg1: FieldEdge) => T,
): T | undefined =>
	edges
		?.map((edge: FieldSetEdge) => edge?.node?.fields?.edges?.[0])
		.filter((edge?: FieldEdge) => edge?.node?.fieldId === fieldId)
		.filter(isNonNullish)
		.map(mapperFn)[0];

export type Props = {
	issueKey: IssueKey;
	isResolved?: boolean | null;
	isSelected?: boolean;
	fieldSets: footer_issueNavigator_FooterInternal$key;
};

const FooterInternal = ({ issueKey, isResolved: isResolvedProp, isSelected, fieldSets }: Props) => {
	const { formatMessage } = useIntl();
	const fieldSetsData = useFragment<footer_issueNavigator_FooterInternal$key>(
		graphql`
			fragment footer_issueNavigator_FooterInternal on JiraIssueFieldSetConnection {
				edges {
					node {
						fields {
							edges {
								node {
									... on JiraSingleSelectUserPickerField {
										fieldId
										name
										user {
											name
											picture
										}
									}
									... on JiraIssueTypeField {
										fieldId
										issueType {
											name
											avatar {
												small
											}
										}
									}
									... on JiraStatusCategoryField {
										fieldId
										statusCategory {
											statusCategoryId
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		fieldSets,
	);
	const fieldSetEdges = fieldSetsData?.edges;

	const assignee = byFieldId(fieldSetEdges, 'assignee', (assigneeEdge) => assigneeEdge?.node?.user);

	const issueType = byFieldId(
		fieldSetEdges,
		'issuetype',
		(issueTypeEdge) => issueTypeEdge?.node?.issueType,
	);

	const statusCategoryId = byFieldId(
		fieldSetEdges,
		'statusCategory',
		(statusCategoryEdge) => statusCategoryEdge?.node?.statusCategory?.statusCategoryId,
	);

	const isResolved = shouldDisplayStrikethrough(
		isResolvedProp ?? undefined,
		statusCategoryForId(statusCategoryId),
	);

	return (
		<StyledContainer>
			<TypeIcon src={issueType?.avatar?.small ?? undefined} alt={issueType?.name ?? undefined} />
			<KeyText
				isSelected={isSelected}
				isResolved={isResolved}
				isVisualRefresh={isVisualRefreshEnabled()}
			>
				{issueKey}
				{isResolved && (
					<ScreenReaderText role="status">
						{formatMessage(messages.screenReaderFooterKeyResolved, { issueKey })}
					</ScreenReaderText>
				)}
			</KeyText>
			<AvatarStyledContainer>
				<Tooltip content={assignee?.name}>
					<Avatar src={assignee?.picture} name={assignee?.name} size="small" />
				</Tooltip>
			</AvatarStyledContainer>
		</StyledContainer>
	);
};

export const Footer = memo(FooterInternal);

// Cleanup with jiv-18659-query-status-for-issuekey-strikethrough
type IssueTypeOld = {
	name?: string | null;
	iconSrc?: string | null;
};

// Cleanup with jiv-18659-query-status-for-issuekey-strikethrough
type PropsOld = {
	issueKey: IssueKey;
	isSelected?: boolean;
	issueType: IssueTypeOld;
	statusCategoryId?: number;
	avatarSrc: string;
	avatarDisplayName: string;
};

// Cleanup with jiv-18659-query-status-for-issuekey-strikethrough
const FooterOldInternal = ({
	issueKey,
	isSelected,
	statusCategoryId,
	avatarSrc,
	avatarDisplayName,
	issueType,
}: PropsOld) => {
	const { formatMessage } = useIntl();
	const isDone = statusCategoryId === categoryIdForStatusCategory(DONE);

	return (
		<StyledContainer>
			{/* @ts-expect-error types should not be null */}
			<TypeIcon src={issueType?.iconSrc} alt={issueType?.name} />
			<KeyText
				isSelected={isSelected}
				isResolved={isDone}
				{...(fg('nin_serious_accessibility_issues')
					? {}
					: {
							'aria-label': isDone
								? formatMessage(messages.screenReaderFooterKeyDone, { issueKey })
								: undefined,
						})}
				isVisualRefresh={isVisualRefreshEnabled()}
			>
				{issueKey}
				{isDone && fg('nin_serious_accessibility_issues') && (
					<ScreenReaderText role="status">
						{formatMessage(messages.screenReaderFooterKeyDone, { issueKey })}
					</ScreenReaderText>
				)}
			</KeyText>
			<AvatarStyledContainer>
				<Tooltip content={avatarDisplayName}>
					<Avatar src={avatarSrc} name={avatarDisplayName} size="small" />
				</Tooltip>
			</AvatarStyledContainer>
		</StyledContainer>
	);
};

// Cleanup with jiv-18659-query-status-for-issuekey-strikethrough
export const FooterOld = memo(FooterOldInternal);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TypeIcon = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	marginRight: token('space.050', '4px'),
	borderRadius: '2px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarStyledContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3.5}px`,
	paddingLeft: token('space.100', '8px'),
	marginLeft: 'auto',
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KeyText = styled.div<{
	isSelected: boolean | undefined;
	isResolved: boolean;
	isVisualRefresh: boolean | undefined;
}>({
	font: token('font.body.UNSAFE_small'),
	// relative position required to prevent screen reader text from overflowing the card list scroll container
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	position: () =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		fg('nin_serious_accessibility_issues') ||
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		fg('jiv-18659-query-status-for-issuekey-strikethrough')
			? 'relative'
			: undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	fontWeight: ({ isVisualRefresh }) =>
		isVisualRefresh ? token('font.weight.medium') : token('font.weight.bold'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ isSelected }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isSelected ? token('color.text.selected', colors.B400) : token('color.text', colors.N200),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: ({ isResolved }) => (isResolved ? 'line-through' : 'none'),
});
