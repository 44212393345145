import React, { useEffect, memo, forwardRef, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { main_issueNavigator_Card_fieldSetsForIssueSearchView$key as FieldSetsForIssueSearchViewKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Card_fieldSetsForIssueSearchView.graphql';
import type { main_issueNavigator_Card_fragment$key as CardFragmentKey } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_Card_fragment.graphql';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { CardBaseStyle } from '../../../../../common/ui/styled';
import { Footer, FooterOld, byFieldId } from './footer';

export type Props = {
	isSelected: boolean;
	fragment: CardFragmentKey;
	style?: React.CSSProperties;
	onClick: (event: MouseEvent) => void;
	onFocus: (() => void) | undefined;
	onBlur: (() => void) | undefined;
	onMounted?: () => void;
	onContextMenu: () => void;
};

const backgroundColorSelected = token('color.background.selected', colors.B50);
const backgroundColorHovered = token('color.background.neutral.subtle.hovered', colors.N10);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledListElement = styled.li({
	marginTop: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledContainer = styled(CardBaseStyle)<{ isSelected?: boolean }>({
	// relative position added to prevent absolute positioned elements inside from escaping the card
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	position: () =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		fg('nin_serious_accessibility_issues') ||
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		fg('jiv-18659-query-status-for-issuekey-strikethrough')
			? 'relative'
			: undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: (props) => (props.isSelected ? token('color.text.selected', colors.B400) : 'inherit'),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: (props) =>
			props.isSelected ? backgroundColorSelected : backgroundColorHovered,
		color: 'inherit',
	},
	'&:focus': {
		color: 'inherit',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryStyledContainer = styled.div({
	wordWrap: 'break-word',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',
	marginBottom: token('space.050', '4px'),
});

const Card = forwardRef<HTMLElement, Props>(
	(
		{ style, onMounted, onClick, onFocus, onBlur, fragment, isSelected, onContextMenu }: Props,
		ref,
	) => {
		const fragmentData = useFragment<CardFragmentKey>(
			graphql`
				fragment main_issueNavigator_Card_fragment on JiraIssue {
					key @required(action: THROW)
					isResolved @include(if: $queryStatusForIssuekeyStrikethrough)
					fieldSetsForIssueSearchView(
						namespace: $namespace
						viewId: $viewId
						first: $amountOfColumns
						filterId: $filterId
					) @required(action: THROW) {
						...main_issueNavigator_Card_fieldSetsForIssueSearchView
						...footer_issueNavigator_FooterInternal
					}
				}
			`,
			fragment,
		);
		const fieldSetsForIssueSearchViewData = useFragment<FieldSetsForIssueSearchViewKey>(
			graphql`
				# eslint-disable-next-line @atlassian/relay/unused-fields -- "fields" is used in the byFiledId util.
				fragment main_issueNavigator_Card_fieldSetsForIssueSearchView on JiraIssueFieldSetConnection {
					edges {
						node {
							# eslint-disable-next-line @atlassian/relay/unused-fields -- "fields" is used in the byFiledId util.
							fields {
								edges {
									node {
										... on JiraSingleLineTextField {
											# eslint-disable-next-line @atlassian/relay/unused-fields -- used in the byFiledId util.
											fieldId
											name
											text
										}
										# Should be able to clean up following spreads (User, Issue Type, and Status Category) with jiv-18659-query-status-for-issuekey-strikethrough
										... on JiraSingleSelectUserPickerField {
											# eslint-disable-next-line @atlassian/relay/unused-fields -- used in the byFiledId util.
											fieldId
											name
											user {
												name
												picture
											}
										}
										... on JiraIssueTypeField {
											# eslint-disable-next-line @atlassian/relay/unused-fields -- used in the byFiledId util.
											fieldId
											issueType {
												name
												avatar {
													small
												}
											}
										}
										... on JiraStatusCategoryField {
											# eslint-disable-next-line @atlassian/relay/unused-fields -- used in the byFiledId util.
											fieldId
											statusCategory {
												statusCategoryId
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			fragmentData.fieldSetsForIssueSearchView,
		);

		const issueKey = fragmentData.key;

		useEffect(() => {
			onMounted && onMounted();
		}, [onMounted]);

		const fieldEdges = fieldSetsForIssueSearchViewData?.edges ?? null;

		const summary = byFieldId(fieldEdges, 'summary', (summaryEdge) => summaryEdge?.node?.text);

		const avatarUser = byFieldId(
			fieldEdges,
			'assignee',
			(assigneeEdge) => assigneeEdge?.node?.user,
		);

		const avatarSrc = avatarUser?.picture || '';
		const avatarDisplayName = avatarUser?.name || '';

		const type = byFieldId(
			fieldEdges,
			'issuetype',
			(issueTypeEdge) => issueTypeEdge?.node?.issueType,
		);

		const issueType = { iconSrc: type?.avatar?.small || '', name: type?.name || '' };

		const statusCategoryId =
			byFieldId(
				fieldEdges,
				'statusCategory',
				(statusCategoryEdge) => statusCategoryEdge?.node?.statusCategory?.statusCategoryId,
			) ?? -1;

		return (
			<StyledListElement
				data-testid="issue-navigator.ui.issue-results.detail-view.card-list.card.list-item"
				{...(fg('nin_serious_accessibility_issues')
					? {}
					: { role: 'option', 'aria-selected': isSelected })}
			>
				<StyledContainer
					// @ts-expect-error incorrect ref type
					ref={ref ?? undefined}
					onContextMenu={onContextMenu}
					isSelected={isSelected}
					onBlur={onBlur}
					tabIndex={isSelected ? 0 : -1}
					onClick={onClick}
					onFocus={onFocus}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={/* eslint-disable-line jira/react/no-style-attribute */ style}
					data-testid="issue-navigator.ui.issue-results.detail-view.card-list.card"
					href={`/browse/${issueKey}`}
				>
					<SummaryStyledContainer data-testid="issue-navigator.ui.issue-results.detail-view.card-list.card.summary">
						{summary}
					</SummaryStyledContainer>
					{fg('jiv-18659-query-status-for-issuekey-strikethrough') ? (
						<Footer
							issueKey={toIssueKey(issueKey)}
							isResolved={fragmentData.isResolved}
							fieldSets={fragmentData.fieldSetsForIssueSearchView}
							isSelected={isSelected}
						/>
					) : (
						<FooterOld
							issueKey={toIssueKey(issueKey)}
							isSelected={isSelected}
							statusCategoryId={Number(statusCategoryId)}
							avatarSrc={avatarSrc}
							avatarDisplayName={avatarDisplayName}
							issueType={issueType}
						/>
					)}
				</StyledContainer>
			</StyledListElement>
		);
	},
);

export default memo(Card);
