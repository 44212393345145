import React, { Component } from 'react';
import OriginTracing from '@atlassiansox/origin-tracing';
// @ts-expect-error - TS2305 - Module '"@atlaskit/share"' has no exported member 'OptionData'.
import { ShareDialogContainer, type Flag, type OptionData } from '@atlaskit/share';
import type { ShareServiceClient } from '@atlaskit/share/clients';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { withFlagService, type FlagService } from '@atlassian/jira-flags';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { LegacyShareClientConfig } from '../../common/types';
import { createAri } from '../../common/utils/ari';
import LegacyShareClient from '../../services/legacy-share-client';
import type { Props, UserData, ServerUsersAndGroups } from './types';

export type ButtonProps = Props & {
	flagService: FlagService;
	onTriggerButtonClick?: () => void;
	shouldAutoDismissFlag?: boolean;
	legacyShareClientConfig?: LegacyShareClientConfig;
	orgId?: string;
	isBrowseUsersEnabled?: boolean;
	shareFormHelperMessage?: string;
	shareFieldsFooter?: React.ReactNode;
	workspaceAri?: string;
} & Intl;

const USER_PICKER_LIMIT = 20;

// eslint-disable-next-line jira/react/no-class-components
export class ShareButton extends Component<ButtonProps> {
	static defaultProps = {
		shouldAutoDismissFlag: true,
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onTriggerButtonClick: () => {},
	};

	constructor(props: ButtonProps) {
		super(props);
		this.cloudId = getTenantContext_DEPRECATED_DO_NOT_USE().cloudId;

		if (props.legacyShareClientConfig !== undefined) {
			this.legacyShareClient = new LegacyShareClient(props.legacyShareClientConfig);
		}
	}

	legacyShareClient: undefined | ShareServiceClient;

	getOriginTracingInstance = (): OriginTracing =>
		new OriginTracing({ product: this.props.productId });

	loadUsers = async (searchText = ''): Promise<OptionData[]> => {
		const searchTextEncoded = encodeURIComponent(searchText);
		const url = `/rest/api/3/groupuserpicker?maxResults=${USER_PICKER_LIMIT}&query=${searchTextEncoded}&showAvatar=true&avatarSize=medium&excludeConnectAddons=true`;
		const groupsAndUsers: ServerUsersAndGroups = await fetchJson(url);
		return groupsAndUsers.users.users
			.filter((user) => user.accountType === 'atlassian')
			.map((user) => {
				const userData: UserData = {
					type: 'user',
					id: user.accountId,
					name: user.displayName,
					avatarUrl: user.avatarUrl,
				};
				return userData;
			});
	};

	showFlags = (flags: Flag[]) => {
		const { flagService, shouldAutoDismissFlag } = this.props;
		if (flagService.showFlag) {
			flags.forEach((flag: Flag) => {
				flagService.showFlag({
					type: flag.appearance,
					title: flag.title.defaultMessage,
					isAutoDismiss: shouldAutoDismissFlag,
				});
			});
		}
	};

	cloudId = '';

	render() {
		const {
			objectAri,
			renderCustomTriggerButton,
			shareLink,
			shareTitle,
			shareContentType,
			shareFormTitle,
			subProductId,
			triggerButtonAppearance = 'subtle',
			triggerButtonStyle,
			onTriggerButtonClick,
			shareIntegrations,
			integrationMode = 'off',
			isAutoOpenDialog,
			onDialogClose,
			copyTooltipText,
			orgId,
			isBrowseUsersEnabled,
			shareFormHelperMessage,
			shareFieldsFooter,
		} = this.props;

		return (
			<ShareDialogContainer
				shareClient={this.legacyShareClient}
				triggerButtonAppearance={triggerButtonAppearance}
				triggerButtonStyle={triggerButtonStyle}
				cloudId={this.cloudId}
				orgId={orgId}
				loadUserOptions={this.legacyShareClient && this.loadUsers}
				// @ts-expect-error - TS2322 - Type '() => OriginTracing' is not assignable to type 'OriginTracingFactory'.
				originTracingFactory={this.getOriginTracingInstance}
				productId={subProductId}
				renderCustomTriggerButton={renderCustomTriggerButton}
				shareAri={objectAri || createAri(subProductId, this.cloudId)}
				shareFormHelperMessage={shareFormHelperMessage}
				shareLink={shareLink}
				shareTitle={shareTitle}
				shareContentType={shareContentType}
				shareFormTitle={shareFormTitle}
				showFlags={this.showFlags}
				enableSmartUserPicker={!this.legacyShareClient}
				product="jira"
				onTriggerButtonClick={onTriggerButtonClick}
				shareIntegrations={shareIntegrations}
				integrationMode={integrationMode}
				isAutoOpenDialog={isAutoOpenDialog}
				onDialogClose={onDialogClose}
				copyTooltipText={copyTooltipText}
				isBrowseUsersDisabled={isBrowseUsersEnabled === false}
				shareFieldsFooter={shareFieldsFooter}
				workspaceAri={this.props.workspaceAri}
			/>
		);
	}
}

export default injectIntl(withFlagService(ShareButton));
