import React, { useCallback, type MouseEvent, forwardRef } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import ChevronDown from '@atlaskit/icon/utility/migration/chevron-down';
import Tooltip from '@atlaskit/tooltip';
import DisabledButtonWithTooltip from '@atlassian/jira-disabled-button-with-tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useSelectedIssueStateOldActions } from '../../../../../../controllers/selected-issue-state-old';
import {
	useNextIssueKey,
	useSelectedIssueActions,
} from '../../../../../../controllers/selected-issue/hooks.tsx';
import messages from './messages';

export type Props = {
	totalResultCount: number;
	/**
	 * Position of the currently selected issue in the search results.
	 */
	selectedIssuePosition: number;
	isCappingIssueSearchResult: boolean;
};

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	<StyledTooltipTag ref={ref as (instance: HTMLElement | null) => void} {...props} />
));

const IssuePaginationNextButton = ({
	selectedIssuePosition,
	totalResultCount,
	isCappingIssueSearchResult,
}: Props) => {
	const { formatMessage } = useIntl();

	const isDisabled = selectedIssuePosition === totalResultCount;
	let selectNextIssue;
	let issueKey;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const actions = useSelectedIssueActions();
		selectNextIssue = actions.selectNextIssue;
		// eslint-disable-next-line react-hooks/rules-of-hooks
		issueKey = useNextIssueKey();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const actions = useSelectedIssueStateOldActions();
		selectNextIssue = actions.selectNextIssue;
		issueKey = actions.getNextIssue().issueKey;
	}

	const nextIssueMessage = issueKey
		? formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.nextIssueTooltipIssueTermRefresh
					: messages.nextIssueTooltip,
				{ issueKey },
			)
		: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.nextIssueWithoutKeyTooltipIssueTermRefresh
					: messages.nextIssueWithoutKeyTooltip,
			);

	const onClick = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			if (selectNextIssue()) {
				fireUIAnalytics(analyticsEvent, 'nextIssue');
			}
		},
		[selectNextIssue],
	);

	if (isDisabled && isCappingIssueSearchResult) {
		return (
			<Tooltip
				content={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.disabledButtonTooltipIssueTermRefresh
						: messages.disabledButtonTooltip,
				)}
				tag={TooltipTag}
			>
				<DisabledButtonWithTooltip
					tabIndex={0}
					isDisabled
					data-testid="issue-navigator.common.ui.issue-app.header-actions.issue-pagination.next-button.next-disabled-button"
				>
					<IconButton
						icon={ChevronDown}
						label={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.disabledButtonTooltipIssueTermRefresh
								: messages.disabledButtonTooltip,
						)}
						onClick={onClick}
						appearance="subtle"
						testId="issue-navigator.common.ui.issue-app.header-actions.issue-pagination.next-button"
						isDisabled={isDisabled}
						spacing="compact"
					/>
					)
				</DisabledButtonWithTooltip>
			</Tooltip>
		);
	}

	return (
		<IconButton
			icon={ChevronDown}
			label={
				isDisabled
					? formatMessage(messages.disabledButtonLabel, { issueCount: totalResultCount })
					: nextIssueMessage
			}
			onClick={onClick}
			appearance="subtle"
			testId="issue-navigator.common.ui.issue-app.header-actions.issue-pagination.next-button"
			isDisabled={isDisabled}
			spacing="compact"
			isTooltipDisabled={isDisabled}
		/>
	);
};

export default IssuePaginationNextButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTooltipTag = styled.div({
	display: 'inline-flex',
});
