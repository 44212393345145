import OriginTracing from '@atlassiansox/origin-tracing';
import type { Comment, Content, MetaData, ShareResponse, User } from '@atlaskit/share';
import { ShareServiceClient } from '@atlaskit/share/clients';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	LegacyShareClientConfig,
	GetShareEndpointFn,
	GetSharePayloadFn,
} from '../../common/types';

type CategorizedRecipients = {
	userIds: string[];
	emails: string[];
};

/**
 * A Share Client for use with the Legacy Jira Share API for issues and searches.
 * It can be customised with the following functions:
 * - getShareEndpoint: Should return the Legacy Share API endpoint, ex. `/rest/share/1.0/issue/XX-1234`
 * - getSharePayload: (Optional) Any additional fields that should be added to the request to the Share API, ex. `{ jql: 'order by created DESC' }`
 */
export default class LegacyShareClient extends ShareServiceClient {
	getShareEndpoint: GetShareEndpointFn;

	getSharePayload: GetSharePayloadFn;

	constructor({ getShareEndpoint, getSharePayload }: LegacyShareClientConfig) {
		super();
		this.getShareEndpoint = getShareEndpoint;
		this.getSharePayload = getSharePayload;
	}

	categorizeRecipients = (recipients: User[]): CategorizedRecipients => {
		const accumulator: CategorizedRecipients = {
			userIds: [],
			emails: [],
		};

		return recipients.reduce((acc, recipient) => {
			if (recipient.type === 'user') {
				// @ts-expect-error - TS2339 - Property 'email' does not exist on type 'User'.
				if (recipient.email !== undefined) {
					// @ts-expect-error - TS2339 - Property 'email' does not exist on type 'User'.
					acc.emails.push(recipient.email);
					// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'User'.
				} else if (recipient.id !== undefined) {
					// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'User'.
					acc.userIds.push(recipient.id);
				}
			}
			return acc;
		}, accumulator);
	};

	share(
		content: Content,
		recipients: User[],
		metadata: MetaData,
		comment?: Comment,
	): Promise<ShareResponse> {
		const categorizedRecipients = this.categorizeRecipients(recipients);

		const shareRequest = {
			usernames: categorizedRecipients.userIds,
			emails: categorizedRecipients.emails,
			message: comment?.value || '',
			atlOrigin: new OriginTracing({
				id: metadata.atlOriginId,
				product: 'jira',
			}).encode(),
			...(this.getSharePayload ? this.getSharePayload(content) : null),
		};

		return performPostRequest(this.getShareEndpoint(content), {
			body: JSON.stringify(shareRequest),
		}).catch((error) => {
			fireErrorAnalytics({
				error,
				sendToPrivacyUnsafeSplunk: true,
				meta: {
					packageName: 'jiraShareButton',
					id: 'LegacyShareClient.share',
				},
				attributes: {
					contentType: content.type,
				},
			});
			throw error;
		});
	}
}
