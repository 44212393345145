import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	successOptInTitle: {
		id: 'issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-opt-in-title',
		defaultMessage: "You've switched to the new issue search",
		description: 'Success title when user opts into NIN',
	},
	successOptInDesc: {
		id: 'issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-opt-in-desc',
		defaultMessage:
			'Say hello to a faster, cleaner, and more intuitive experience while searching for issues across Jira.',
		description: 'Message in flag when user opts into NIN',
	},
	successActionLearnMoreLink: {
		id: 'issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-action-learn-more-link',
		defaultMessage: 'Learn more',
		description:
			'Text for the "Learn more" button on the opt-in success flag, takes user to a page with information about the new Issue Navigator',
	},
	successOptInTitleIssueTermRefresh: {
		id: 'issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-opt-in-title-issue-term-refresh',
		defaultMessage: "You've switched to the new issue search",
		description: 'Success title when user opts into NIN',
	},
	successOptInDescIssueTermRefresh: {
		id: 'issue-navigator-changeboarding.controllers.enable-nin-changeboarding.success-opt-in-desc-issue-term-refresh',
		defaultMessage:
			'Say hello to a faster, cleaner, and more intuitive experience while searching for issues across Jira.',
		description: 'Message in flag when user opts into NIN',
	},
});
export default messages;
