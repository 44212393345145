/**
 * @generated SignedSource<<f0d0f1b97573e646715b99b44353d352>>
 * @relayHash c7db3b62d1ad97719940fb9f1112e6fb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b2836c705135c5b1875d22c6cee0ce8fdc85d753e9fc50a0c114e198dd50d841

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type IssueNavigatorIssueSearchRefetchQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns?: number | null | undefined;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  issueSearchInput: JiraIssueSearchInput;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  queryStatusForIssuekeyStrikethrough: boolean;
  shouldQueryFieldSetsById: boolean;
  viewId?: string | null | undefined;
};
export type IssueNavigatorIssueSearchRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_refetchQueryNew">;
};
export type IssueNavigatorIssueSearchRefetchQuery = {
  response: IssueNavigatorIssueSearchRefetchQuery$data;
  variables: IssueNavigatorIssueSearchRefetchQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider": isChildIssueTotalCountEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider: {
    readonly get: () => number;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
});

import isChildIssueTotalCountEnabled_provider from '@atlassian/jira-relay-provider/src/is-child-issues-total-count-enabled.relayprovider';
import count_provider from '@atlassian/jira-relay-provider/src/issue-links-count.relayprovider';
import isEntryPointEnabled_provider from '@atlassian/jira-relay-provider/src/issue-links-entrypoint.relayprovider';
import isJscM1ApiUpdatesEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-m1-api-updates.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryStatusForIssuekeyStrikethrough"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v14 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v15 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  (v14/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "options",
    "variableName": "options"
  }
],
v16 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v17 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v18 = {
  "kind": "ScalarField",
  "name": "id"
},
v19 = {
  "kind": "ScalarField",
  "name": "key"
},
v20 = {
  "kind": "Variable",
  "name": "filterId",
  "variableName": "filterId"
},
v21 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v22 = {
  "kind": "Variable",
  "name": "namespace",
  "variableName": "namespace"
},
v23 = {
  "kind": "Variable",
  "name": "viewId",
  "variableName": "viewId"
},
v24 = [
  (v20/*: any*/),
  (v21/*: any*/),
  (v22/*: any*/),
  (v23/*: any*/)
],
v25 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v26 = {
  "kind": "ScalarField",
  "name": "name"
},
v27 = {
  "kind": "ScalarField",
  "name": "text"
},
v28 = {
  "kind": "ScalarField",
  "name": "picture"
},
v29 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v30 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v31 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v30/*: any*/),
    (v18/*: any*/)
  ]
},
v32 = {
  "kind": "ScalarField",
  "name": "type"
},
v33 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v34 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v35 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v26/*: any*/),
                (v18/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v36 = {
  "kind": "ScalarField",
  "name": "message"
},
v37 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v36/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v38 = [
  (v26/*: any*/),
  (v18/*: any*/)
],
v39 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v38/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v40 = {
  "kind": "ScalarField",
  "name": "date"
},
v41 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v42 = {
  "kind": "ScalarField",
  "name": "lazyIsEditableInIssueView"
},
v43 = {
  "kind": "ClientExtension",
  "selections": [
    (v42/*: any*/)
  ]
},
v44 = {
  "kind": "InlineFragment",
  "selections": [
    (v40/*: any*/),
    (v26/*: any*/),
    (v41/*: any*/),
    (v43/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v45 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
},
v46 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v19/*: any*/),
        {
          "kind": "ScalarField",
          "name": "summary"
        },
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v47 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v48 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatusCategory",
      "kind": "LinkedField",
      "name": "statusCategory",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v30/*: any*/),
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusCategoryField"
},
v49 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v31/*: any*/),
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v50 = [
  (v26/*: any*/)
],
v51 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v52 = {
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "JiraLabelConnection",
  "kind": "LinkedField",
  "name": "selectedLabelsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraLabelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraLabel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v50/*: any*/)
        }
      ]
    },
    (v51/*: any*/)
  ],
  "storageKey": "selectedLabelsConnection(first:1000)"
},
v53 = {
  "kind": "InlineFragment",
  "selections": [
    (v52/*: any*/),
    (v26/*: any*/),
    (v43/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v54 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v29/*: any*/),
        (v26/*: any*/),
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraIssueTypeField"
},
v55 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v56 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v19/*: any*/),
        {
          "concreteType": "JiraSingleLineTextField",
          "kind": "LinkedField",
          "name": "summaryField",
          "plural": false,
          "selections": [
            (v27/*: any*/),
            (v18/*: any*/)
          ]
        },
        (v18/*: any*/),
        {
          "concreteType": "JiraColorField",
          "kind": "LinkedField",
          "name": "issueColorField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraColor",
              "kind": "LinkedField",
              "name": "color",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "colorKey"
                },
                (v18/*: any*/)
              ]
            },
            (v18/*: any*/)
          ]
        },
        {
          "concreteType": "JiraIssueTypeField",
          "kind": "LinkedField",
          "name": "issueTypeField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "issueType",
              "plural": false,
              "selections": [
                (v55/*: any*/),
                (v26/*: any*/),
                (v18/*: any*/)
              ]
            },
            (v18/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v57 = {
  "concreteType": "JiraPriority",
  "kind": "LinkedField",
  "name": "priority",
  "plural": false,
  "selections": [
    (v26/*: any*/),
    {
      "kind": "ScalarField",
      "name": "iconUrl"
    },
    (v18/*: any*/)
  ]
},
v58 = {
  "kind": "InlineFragment",
  "selections": [
    (v57/*: any*/),
    (v26/*: any*/),
    (v43/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v59 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v19/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v60 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v38/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v61 = {
  "concreteType": "JiraSprintConnection",
  "kind": "LinkedField",
  "name": "selectedSprintsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraSprintEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraSprint",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v18/*: any*/),
            {
              "kind": "ScalarField",
              "name": "sprintId"
            },
            (v26/*: any*/),
            {
              "kind": "ScalarField",
              "name": "state"
            },
            {
              "kind": "ScalarField",
              "name": "endDate"
            }
          ]
        }
      ]
    }
  ]
},
v62 = {
  "kind": "InlineFragment",
  "selections": [
    (v61/*: any*/),
    (v26/*: any*/),
    (v43/*: any*/)
  ],
  "type": "JiraSprintField"
},
v63 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v64 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v65 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    (v63/*: any*/),
    (v28/*: any*/),
    (v26/*: any*/),
    (v18/*: any*/),
    (v64/*: any*/)
  ]
},
v66 = {
  "kind": "InlineFragment",
  "selections": [
    (v26/*: any*/),
    {
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v38/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v67 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v68 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v55/*: any*/),
        (v18/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v69 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v70 = [
  (v51/*: any*/)
],
v71 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                (v69/*: any*/)
              ]
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v70/*: any*/)
        },
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "webUrl"
                    },
                    (v19/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v17/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v31/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v18/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v18/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v18/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueLinkField"
},
v72 = {
  "kind": "ScalarField",
  "name": "isStoryPointField"
},
v73 = {
  "kind": "ScalarField",
  "name": "number"
},
v74 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v72/*: any*/),
        (v73/*: any*/),
        (v26/*: any*/),
        (v43/*: any*/)
      ],
      "type": "JiraNumberField"
    }
  ]
},
v75 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fieldSetIds",
          "variableName": "fieldSetIds"
        },
        (v21/*: any*/)
      ],
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsById",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v32/*: any*/),
                (v33/*: any*/),
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v17/*: any*/),
                            (v25/*: any*/),
                            (v32/*: any*/),
                            (v34/*: any*/),
                            (v18/*: any*/),
                            (v35/*: any*/),
                            (v37/*: any*/),
                            (v39/*: any*/),
                            (v44/*: any*/),
                            (v45/*: any*/),
                            (v46/*: any*/),
                            (v47/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v27/*: any*/),
                                (v26/*: any*/),
                                (v43/*: any*/)
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            (v48/*: any*/),
                            (v49/*: any*/),
                            (v53/*: any*/),
                            (v54/*: any*/),
                            (v56/*: any*/),
                            (v58/*: any*/),
                            (v59/*: any*/),
                            (v60/*: any*/),
                            (v62/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v65/*: any*/),
                                (v26/*: any*/),
                                (v41/*: any*/),
                                (v43/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            (v66/*: any*/),
                            (v67/*: any*/),
                            (v68/*: any*/),
                            (v71/*: any*/),
                            (v74/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v76 = {
  "condition": "shouldQueryFieldSetsById",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "args": (v24/*: any*/),
      "concreteType": "JiraIssueFieldSetConnection",
      "kind": "LinkedField",
      "name": "fieldSetsForIssueSearchView",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldSetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueFieldSet",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v32/*: any*/),
                (v33/*: any*/),
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v25/*: any*/),
                            (v32/*: any*/),
                            (v34/*: any*/),
                            (v35/*: any*/),
                            (v37/*: any*/),
                            (v39/*: any*/),
                            (v44/*: any*/),
                            (v45/*: any*/),
                            (v46/*: any*/),
                            (v47/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v32/*: any*/),
                                (v43/*: any*/)
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "concreteType": "JiraStatusCategory",
                                  "kind": "LinkedField",
                                  "name": "statusCategory",
                                  "plural": false,
                                  "selections": (v50/*: any*/)
                                }
                              ],
                              "type": "JiraStatusCategoryField"
                            },
                            (v49/*: any*/),
                            (v53/*: any*/),
                            (v56/*: any*/),
                            (v58/*: any*/),
                            (v59/*: any*/),
                            (v60/*: any*/),
                            (v62/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v32/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    (v63/*: any*/),
                                    (v64/*: any*/)
                                  ]
                                },
                                (v41/*: any*/),
                                (v43/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            (v66/*: any*/),
                            (v67/*: any*/),
                            (v68/*: any*/),
                            (v71/*: any*/),
                            (v74/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v77 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v78 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v79 = [
  (v77/*: any*/),
  (v16/*: any*/),
  (v78/*: any*/)
],
v80 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v81 = {
  "kind": "ScalarField",
  "name": "displayName"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "name": "IssueNavigatorIssueSearchRefetchQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_refetchQueryNew"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "IssueNavigatorIssueSearchRefetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v15/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearch",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v16/*: any*/),
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "issueId"
                      },
                      {
                        "args": (v24/*: any*/),
                        "concreteType": "JiraIssueFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSetsForIssueSearchView",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldSetEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSet",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fields",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v17/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v25/*: any*/),
                                                  (v26/*: any*/),
                                                  (v27/*: any*/)
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v25/*: any*/),
                                                  (v26/*: any*/),
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                      (v17/*: any*/),
                                                      (v26/*: any*/),
                                                      (v28/*: any*/),
                                                      (v18/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraSingleSelectUserPickerField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v25/*: any*/),
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueType",
                                                    "plural": false,
                                                    "selections": [
                                                      (v26/*: any*/),
                                                      (v29/*: any*/),
                                                      (v18/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v25/*: any*/),
                                                  (v31/*: any*/)
                                                ],
                                                "type": "JiraStatusCategoryField"
                                              },
                                              (v18/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "queryStatusForIssuekeyStrikethrough",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "isResolved"
                          },
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "ids",
                                "value": [
                                  "status"
                                ]
                              }
                            ],
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fieldsById",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v17/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v25/*: any*/),
                                          {
                                            "concreteType": "JiraStatus",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                              (v31/*: any*/),
                                              (v18/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraStatusField"
                                      },
                                      (v18/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldsById(ids:[\"status\"])"
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          (v75/*: any*/),
                          (v76/*: any*/)
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v75/*: any*/),
                          (v76/*: any*/),
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "issueRowFieldSets",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v32/*: any*/),
                                          (v33/*: any*/),
                                          {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v17/*: any*/),
                                                      (v25/*: any*/),
                                                      (v32/*: any*/),
                                                      (v34/*: any*/),
                                                      (v18/*: any*/),
                                                      (v35/*: any*/),
                                                      (v37/*: any*/),
                                                      (v39/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v40/*: any*/),
                                                          (v42/*: any*/),
                                                          (v26/*: any*/),
                                                          (v41/*: any*/)
                                                        ],
                                                        "type": "JiraDatePickerField"
                                                      },
                                                      (v45/*: any*/),
                                                      (v46/*: any*/),
                                                      (v47/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v27/*: any*/),
                                                          (v42/*: any*/),
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraSingleLineTextField"
                                                      },
                                                      (v48/*: any*/),
                                                      (v49/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v52/*: any*/),
                                                          (v42/*: any*/),
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraLabelsField"
                                                      },
                                                      (v54/*: any*/),
                                                      (v56/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v42/*: any*/),
                                                          (v57/*: any*/),
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraPriorityField"
                                                      },
                                                      (v59/*: any*/),
                                                      (v60/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v42/*: any*/),
                                                          (v61/*: any*/),
                                                          (v26/*: any*/)
                                                        ],
                                                        "type": "JiraSprintField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v65/*: any*/),
                                                          (v42/*: any*/),
                                                          (v26/*: any*/),
                                                          (v41/*: any*/)
                                                        ],
                                                        "type": "JiraSingleSelectUserPickerField"
                                                      },
                                                      (v66/*: any*/),
                                                      (v67/*: any*/),
                                                      (v68/*: any*/),
                                                      (v71/*: any*/),
                                                      {
                                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                                                        "kind": "Condition",
                                                        "passingValue": true,
                                                        "selections": [
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              (v72/*: any*/),
                                                              (v73/*: any*/),
                                                              (v42/*: any*/),
                                                              (v26/*: any*/)
                                                            ],
                                                            "type": "JiraNumberField"
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "childIssues",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "activeProjectsOnly",
                                        "value": true
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "first",
                                        "value": 10
                                      }
                                    ],
                                    "concreteType": "JiraIssueConnection",
                                    "kind": "LinkedField",
                                    "name": "issues",
                                    "plural": false,
                                    "selections": (v70/*: any*/),
                                    "storageKey": "issues(activeProjectsOnly:true,first:10)"
                                  }
                                ],
                                "type": "JiraChildIssuesWithinLimit"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "isHighlightedIssueRow"
                          }
                        ]
                      }
                    ]
                  },
                  (v17/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueNavigatorPageInfo",
                "kind": "LinkedField",
                "name": "issueNavigatorPageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "firstIssuePosition"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "lastIssuePosition"
                  }
                ]
              },
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "maxCursors",
                    "value": 7
                  }
                ],
                "concreteType": "JiraPageCursors",
                "kind": "LinkedField",
                "name": "pageCursors",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "around",
                    "plural": true,
                    "selections": (v79/*: any*/)
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "last",
                    "plural": false,
                    "selections": (v79/*: any*/)
                  },
                  {
                    "concreteType": "JiraPageCursor",
                    "kind": "LinkedField",
                    "name": "first",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v77/*: any*/),
                      (v78/*: any*/)
                    ]
                  }
                ],
                "storageKey": "pageCursors(maxCursors:7)"
              },
              {
                "kind": "ScalarField",
                "name": "totalIssueSearchResultCount"
              },
              {
                "kind": "LinkedField",
                "name": "issueSearchError",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "messages"
                      }
                    ],
                    "type": "JiraInvalidJqlError"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v36/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "errorType"
                      }
                    ],
                    "type": "JiraInvalidSyntaxError"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "isCappingIssueSearchResult"
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  (v69/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "hasPreviousPage"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "startCursor"
                  }
                ]
              },
              (v80/*: any*/)
            ]
          },
          {
            "args": (v15/*: any*/),
            "filters": [
              "cloudId",
              "issueSearchInput",
              "options"
            ],
            "handle": "connection",
            "key": "IssueNavigatorIssueSearchPagination__issueSearch",
            "kind": "LinkedHandle",
            "name": "issueSearch"
          },
          {
            "args": [
              (v14/*: any*/),
              (v20/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/)
            ],
            "kind": "LinkedField",
            "name": "issueSearchViewResult",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isJiraIssueSearchViewResult"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "viewId"
                  },
                  (v18/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "hasDefaultFieldSets"
                  },
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                          "fieldSetSelectedState": "SELECTED"
                        }
                      },
                      (v21/*: any*/)
                    ],
                    "concreteType": "JiraIssueSearchFieldSetConnection",
                    "kind": "LinkedField",
                    "name": "fieldSets",
                    "plural": false,
                    "selections": [
                      (v51/*: any*/),
                      {
                        "concreteType": "JiraIssueSearchFieldSetEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraIssueSearchFieldSet",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v33/*: any*/),
                              (v32/*: any*/),
                              (v81/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "jqlTerm"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "isSortable"
                              },
                              {
                                "concreteType": "JiraFieldSetPreferences",
                                "kind": "LinkedField",
                                "name": "fieldSetPreferences",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "width"
                                  }
                                ]
                              },
                              {
                                "concreteType": "JiraFieldType",
                                "kind": "LinkedField",
                                "name": "fieldType",
                                "plural": false,
                                "selections": [
                                  (v81/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v80/*: any*/)
                    ]
                  }
                ],
                "type": "JiraIssueSearchView"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v36/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "statusCode"
                      }
                    ]
                  }
                ],
                "type": "QueryError"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v18/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b2836c705135c5b1875d22c6cee0ce8fdc85d753e9fc50a0c114e198dd50d841",
    "metadata": {},
    "name": "IssueNavigatorIssueSearchRefetchQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcischildissuestotalcountenabledrelayprovider": isChildIssueTotalCountEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm1apiupdatesrelayprovider": isJscM1ApiUpdatesEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinksentrypointrelayprovider": isEntryPointEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcissuelinkscountrelayprovider": count_provider
    }
  }
};
})();

(node as any).hash = "611c62c80fa368ece022de74701e875a";

export default node;
