import React from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link';
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type WarningContentProps = {
	isEmailEnabled: boolean | undefined;
	canUserEnableEmail: boolean;
	isUserHIPAA: boolean;
};

export function WarningContent({
	isEmailEnabled,
	canUserEnableEmail,
	isUserHIPAA,
}: WarningContentProps) {
	if (isEmailEnabled === false || isUserHIPAA) {
		return (
			<ContextualAnalyticsData sourceType={MODAL} sourceName="PreShareWarningView">
				<WarningIcon
					size="medium"
					label="Warning"
					testId="share-button.ui.pre-share-view.warning-content.warning-icon"
				/>
				{isEmailEnabled === false && (
					<p>
						<FormattedMessage {...messages.disabledWarningMessage} /> <br />
						{canUserEnableEmail ? (
							<>
								<FormattedMessage {...messages.adminEnableOutgoingMail} />{' '}
								<Link href="/secure/admin/OutgoingMailServers.jspa">
									<FormattedMessage {...messages.outgoingMail} />
								</Link>
							</>
						) : (
							<FormattedMessage {...messages.nonAdminWarningMessage} />
						)}
					</p>
				)}

				{isEmailEnabled !== false && (
					<FormattedMessage {...messages.disabledWarningMessageIfHipaa} />
				)}

				<FireScreenAnalytics
					attributes={{
						canUserEnableEmail,
						isUserHIPAA,
					}}
				/>
			</ContextualAnalyticsData>
		);
	}

	return null;
}
