import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTooltipText: {
		id: 'issue-navigator.common.total-issue-count.error-tooltip-text',
		defaultMessage: 'We couldn’t load the total number of issues. Refresh to try again.',
		description:
			'Inform a user that there was a problem when trying to load the total issue count for their search and ask that they try again',
	},
	errorIconLabel: {
		id: 'issue-navigator.common.total-issue-count.error-icon-label',
		defaultMessage: 'Error loading total issue count',
		description:
			'inform users of an error when trying to load the total issue count, when they are using a screen reader',
	},
	cappedCountTooltip: {
		id: 'issue-navigator.common.total-issue-count.capped-count-tooltip',
		defaultMessage: 'See the total number of issues.',
		description:
			'Tooltip shown over capped issue count indicating it can be clicked to reveal total issue count',
	},
	errorTooltipTextIssueTermRefresh: {
		id: 'issue-navigator.common.total-issue-count.error-tooltip-text-issue-term-refresh',
		defaultMessage: 'We couldn’t load the total number of issues. Refresh to try again.',
		description:
			'Inform a user that there was a problem when trying to load the total issue count for their search and ask that they try again',
	},
	errorIconLabelIssueTermRefresh: {
		id: 'issue-navigator.common.total-issue-count.error-icon-label-issue-term-refresh',
		defaultMessage: 'Error loading total issue count',
		description:
			'inform users of an error when trying to load the total issue count, when they are using a screen reader',
	},
	cappedCountTooltipIssueTermRefresh: {
		id: 'issue-navigator.common.total-issue-count.capped-count-tooltip-issue-term-refresh',
		defaultMessage: 'See the total number of issues.',
		description:
			'Tooltip shown over capped issue count indicating it can be clicked to reveal total issue count',
	},
});
